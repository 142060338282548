.intro {
  font-family: 'Inter', sans-serif;
  margin-bottom: 30px;
  font-size: 16px;
  text-align: justify;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.left-column h1 {
  width: 100%;
}

.intro-columns {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.intro-columns > * {
  flex: 1;
}

.intro-columns h1 {
  max-width: 50%;
  margin-top: -10px;
}

.intro-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.intro-rows {
  max-width: 40%;
}

.intro-row-right {
  font-family: 'Inter', sans-serif;
  flex-grow: 1;
  text-align: right;
}

.intro-row-left {
  font-weight: 500;
  text-align: left;
  width: 40%;
}

.divider {
  background-color: white;
  margin: 10px 0;
  opacity: 0.25;
}

.grid-container {
    display: grid;
    grid-template-rows: repeat(5, auto);
    gap: 20px; 
  }

  .column-container {
    display:none;
  }

  .grid-container img {
    margin-bottom: 10px;
  }
  
  
  
  .grid-row-2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
  }
  
  .grid-row-2 .grid-item {
    flex: 1;
  }

  .custom-modal {
    background-color: black;
    color: white;
  }

  .custom-modal-container {
    width: 80% !important;
    max-width: none !important;
  }
  
  .contact-div {
    width: 100%;
    background: rgb(239,255,4);
    background: linear-gradient(137deg, rgba(239,255,4,1) 25%, rgba(29,29,30,1) 100%, rgba(239,255,4,1) 100%);
    color: black;
    border-radius: 15px; /* Adjust the value as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    margin: 40px 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .centered-logo {
    max-width: 60px;
    align-self: center;
    margin-top: auto;
  }

  .contact-heading {
    font-size: 2.5em;
    font-weight: 500;
  }

  .contact-text {
    font-size: 1.1em;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    width: 60%;
  }

  .ch-a {
    margin-bottom: 0;
  }

  .ch-b {
    color: #052A93;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  .contact-logos {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -12px;
  }
  
  .contact-logo {
    align-self: center;
    max-width: 45px;
    margin: 0 10px;
    filter: brightness(0) saturate(100%) invert(10%) sepia(8%) saturate(148%) hue-rotate(202deg) brightness(91%) contrast(95%);
  }
  
  .contact-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .contact-info {
    margin-top: 20px;
    font-size: 1.2em;
  }
  
  .heading-bottom { 
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .grid-container {
      display: none;
    }

    .column-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 15px;
    }

    .column-container img {
      margin-bottom: 10px;
    }

    .contact-div {
      margin: 20px 0;
      padding: 20px;
      background: linear-gradient(137deg, rgba(239,255,4,1) 40%, rgba(29,29,30,1) 100%, rgba(239,255,4,1) 100%);
    }

    .contact-text {
      width: 75%;
    }

    .custom-modal-container {
      width:95% !important;
      max-width: none !important;
    }

    .intro-columns {
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }

    .intro-columns h1 {
      max-width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    
    .intro-rows {
      max-width: 100%;
      margin-bottom: 20px;
      width: 80%;
    }

    .ch-a {
      margin-bottom: -2px;
    }
  
    .ch-b {
      color: #052A93;
      margin-bottom: 10px;
      line-height: 1.2;
    }
  }