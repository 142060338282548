.scroll-indicator-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 1;
    right: 50px;
    bottom: 0;
    opacity: 1; /* Ensure the initial state is visible */
    transition: opacity 1s, visibility 1s; /* Smooth transition for fading out */
    visibility: visible; /* Ensure the initial state is visible */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .scroll-arrow {
    font-size: 2em;
    color: white; /* Change to match the desired color */
    animation: bounce 2s infinite;
  }
  
  /* Bounce Animation */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  @media (max-width: 1024px) {
    .scroll-indicator-overlay{
        right: 20px;
    }
  }

  @media (max-width: 768px) {
    .scroll-indicator-overlay{
        right: 5px;
    }

    .scroll-arrow {
        font-size: 0.9em;
      }
  }