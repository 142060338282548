html, body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: 'DM Serif Display', serif;
  color: white;
  overflow-x: hidden;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

button {
  font-family: 'Inter', sans-serif;
}

.wrapper {
  padding: 20px 150px 0 150px;
  flex-grow: 1;
}

h1 {
  font-size: 2.5rem;
  padding-bottom: 25px;
}

html {
  scroll-behavior: smooth;
}

@keyframes attention-grab {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.attention {
  animation: attention-grab 1s 0.5s forwards;
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 20px 60px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 20px 20px;
  }

  h1 {
    font-size: 2rem;
    padding-bottom: 15px;
  }
}