.image-container {
    position: relative;
    --a: 1deg; /* control the angle of rotation (the smaller, the better) */
    border-radius: 20px;
    transform: perspective(400px) rotate3d(var(--r,1,-1),0,calc(var(--i,1)*var(--a)));
    -webkit-mask: linear-gradient(135deg,#000c 40%,#000,#000c 60%) 100% 100%/250% 250%;
    transition: .4s;
    cursor: pointer;
    filter: brightness(1.05); 
  }

  .left {
    transform: perspective(400px) rotate3d(var(--r,1,1),0,calc(var(--i,1)*var(--a)));
    -webkit-mask: linear-gradient(225deg,#000c 40%,#000,#000c 60%) 100% 100%/250% 250%;
  }

  .image-container:hover {
    --i: -1;
  -webkit-mask-position: 0 0;
  }

  .image-test {
    width: 100%;
    height: auto;
    z-index: 0;
    
}
  

  .image-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    font-family: 'Inter', sans-serif;
  }

  .thumbnail-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .custom-heading-class {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.custom-heading-class .flex-1 {
  flex: 0 1 auto !important;
  display: inline-block;
  width: fit-content; 
}






  