.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: black; 
    color: white; 
    font-family: 'DM Serif Display', serif;
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer .powered-by {
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
  }
  