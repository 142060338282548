.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 35px;
}

.navbar img {
    width: 25%;
}

.contact-button {
    color: "#EFFF04";
}

.navbar-end {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

button {
    flex-shrink: 0; /* Prevent the button from shrinking */
    
  }
  .button-nav {
    font-size: 16px;
    width: 122px;
    height: 40px;
  }

  @media (max-width: 768px) {
    .navbar {
        padding-bottom: 10px;
    }

    .navbar img {
        width: 35%;
    }

    .button-nav {
        font-size: 14px;
        width: 92px;
        height: 38px;
        flex-shrink: 0;
      }
    .navbar-end {
        gap: 10px;
    }
  }